import { useRouter } from "next/router";

import { LoginForm } from "components/auth/login-form";
import { AuthLayout } from "components/layout/auth-layout";
import HeadSeo from "components/layout/head-seo";

export default function LoginPage() {
  const router = useRouter();

  return (
    <AuthLayout header="Log in">
      <HeadSeo title="Log in" />

      <LoginForm
        mode="page"
        notice={router.query.notice as string}
        error={router.query.error as string}
        redirect={router.query.redirect as string}
      />
    </AuthLayout>
  );
}
