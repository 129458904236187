import { useRouter } from "next/router";

import merge from "lodash/merge";
import { DefaultSeoProps, NextSeo, NextSeoProps } from "next-seo";

import { SEO_IMG_DEFAULT } from "@/lib/constants";
import { seoConfig } from "@/lib/seo/seo-config";

export type HeadSeoProps = {
  title: string;
  description?: string;
  siteName?: string;
  url?: string;
  canonical?: string;
  nextSeoProps?: NextSeoProps;
  keywords?: string[];
  pageImage?: string;
};

/**
 * Build full seo tags from title, desc, canonical and url
 */
const buildSeoMeta = (pageProps: {
  title: string;
  description: string;
  image: string;
  siteName?: string;
  url?: string;
  canonical?: string;
  keywords?: string[];
}): NextSeoProps => {
  const {
    title,
    description = seoConfig.headSeo.description,
    image = SEO_IMG_DEFAULT,
    canonical,
    siteName = seoConfig.headSeo.siteName,
    keywords,
  } = pageProps;
  return {
    title: title,
    description: description,
    canonical: canonical,
    openGraph: {
      site_name: siteName,
      type: "website",
      title: title,
      description: description,
      images: [
        {
          url: image,
        },
      ],
    },
    additionalMetaTags: [
      {
        property: "name",
        content: title,
      },
      {
        property: "description",
        content: description,
      },
      {
        property: "image",
        content: image,
      },
      {
        name: "viewport",
        content: "minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, minimal-ui",
      },
    ].concat(
      // @ts-ignore
      keywords ? [{ name: "keywords", content: keywords.join(", ") }] : [],
    ),
    ...seoConfig.defaultNextSeo,
  };
};

export const HeadSeo = (props: HeadSeoProps): JSX.Element => {
  const router = useRouter();
  const defaultUrl = `${process.env.NEXT_PUBLIC_BASE_URL}${router.asPath}`;

  const { title, description, siteName, canonical = defaultUrl, nextSeoProps = {}, keywords, pageImage } = props;

  let seoObject: DefaultSeoProps = buildSeoMeta({
    title: title + " | Zettlor",
    image: pageImage,
    description: description?.substring(0, 158),
    keywords,
    canonical,
    siteName,
  });

  const seoProps: NextSeoProps = merge(nextSeoProps, seoObject);

  return <NextSeo {...seoProps} />;
};

export default HeadSeo;
