import { useRouter } from "next/router";

import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { zodResolver } from "@hookform/resolvers/zod";
import queryString from "query-string";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import * as z from "zod";

import { Input } from "components/form-inputs/input";
import { AuthSocialButton, Button, Notice, Text } from "components/ui";

import { RESET_PASSWORD_URL, SIGN_UP_URL, SUPPORTED_OAUTH_PROVIDERS } from "@/lib/constants";
import { useApp } from "@/lib/context/app-context";
import { ErrorObject, authErrorParse } from "@/lib/errors";
import { getUserDefaultPage } from "@/lib/user-helpers";

import ZettlorAuthService from "services/zettlor-auth-service";

import { AuthSeparator } from "../auth-separator";

const loginSchema = z.object({
  email: z.string().email(),
  password: z.string(),
});

type LogInFormData = {
  email: string;
  password: string;
};

interface LoginFormProps {
  mode: "page" | "inline";
  onAuthModeChange?: () => void;
  onSuccess?: () => void;
  allowModeChange?: boolean;
  redirect?: string;
  notice?: string;
  error?: string;
}

const zettlorAuthService = new ZettlorAuthService();
export const LoginForm: FC<LoginFormProps> = ({
  redirect,
  notice,
  allowModeChange = true,
  error,
  mode,
  onAuthModeChange,
  onSuccess,
}) => {
  const router = useRouter();
  const { sessionStatus, user, setSession } = useApp();

  const [loading, setLoading] = useState(false);
  const [apiErrors, setApiErrors] = useState<ErrorObject>();
  const [justLoggedIn, setJustLoggedIn] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LogInFormData>({
    resolver: zodResolver(loginSchema),
  });

  // Redirect user to their default page if they're already logged in
  // and redirect if they just submitted the form and logged in
  useEffect(() => {
    const alreadyLoggedIn = !loading && sessionStatus === "authenticated" && !!user;
    const loggedIn = justLoggedIn && sessionStatus === "authenticated" && !!user;
    if (alreadyLoggedIn || loggedIn) {
      router.push(redirect || getUserDefaultPage({ user }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStatus, user]);

  const onSubmit = async (formData: LogInFormData) => {
    setLoading(true);

    const { success, error, data } = await zettlorAuthService.login({
      email: formData.email.toLowerCase(),
      password: formData.password,
    });
    if (!success) {
      setLoading(false);
      return setApiErrors({
        message: error["non_field_errors"] || authErrorParse(error.message),
      });
    }

    setApiErrors(undefined);
    setSession(data.user);

    toast.success("You've successfully signed in");
    if (mode === "inline" && onSuccess) {
      onSuccess();
    } else {
      setJustLoggedIn(true);
    }
  };

  return (
    <div>
      {notice && (
        <Notice variant="info" className="mt-1">
          <InformationCircleIcon className="h-5 w-5 shrink-0" />
          <Text variant="b3">{notice}</Text>
        </Notice>
      )}

      {error && (
        <Notice variant="error" className="mt-1">
          <InformationCircleIcon className="h-5 w-5 shrink-0 text-zettlor-gray-100" />
          <Text variant="b3" className="text-zettlor-gray-100">
            {authErrorParse(error as string)}
          </Text>
        </Notice>
      )}

      {allowModeChange && (
        <p className="mt-2 space-x-1 text-center text-base font-normal sm:text-left md:text-base">
          <span>Don't have an account?</span>
          {mode === "inline" && onAuthModeChange ? (
            <Button variant="link" onClick={onAuthModeChange} className="font-medium">
              Sign up
            </Button>
          ) : (
            <Button
              variant="link"
              href={`${SIGN_UP_URL}?${queryString.stringify(router.query)}`}
              className="font-medium"
            >
              Sign up
            </Button>
          )}
        </p>
      )}

      <div className="my-6 space-y-3">
        {SUPPORTED_OAUTH_PROVIDERS.map((provider) => (
          <AuthSocialButton
            key={provider.value}
            provider={provider}
            redirect={redirect}
            sessionLoading={sessionStatus === "loading"}
          />
        ))}
      </div>

      <AuthSeparator className="mb-6" />

      <form className="space-y-5" onSubmit={handleSubmit(onSubmit)}>
        <Input<LogInFormData>
          label="Email address"
          name="email"
          required
          type="email"
          inputMode="email"
          autoComplete="email"
          register={register}
          error={errors.email?.message}
        />

        <Input<LogInFormData>
          label="Password"
          name="password"
          required
          type="password"
          autoComplete="current-password"
          register={register}
          error={errors.password?.message}
        />

        <Notice variant="error">{apiErrors?.message}</Notice>
        <Button type="submit" className="w-full" loading={loading}>
          Log in
        </Button>
      </form>
      <p className="mt-4 space-x-1 text-center text-base">
        <span>Forgot your password?</span>
        <Button variant="link" href={RESET_PASSWORD_URL}>
          Reset it
        </Button>
      </p>
    </div>
  );
};
