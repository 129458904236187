import axios, { AxiosError } from "axios";
import queryString from "query-string";
import { SessionUser } from "types/models";
import { BaseInvitation } from "types/models/invitationBase";
import { ServiceResponse } from "types/service";
import { UserRole } from "types/userRole";

import { parseApiError } from "@/lib/errors";

interface Credentials {
  email: string | null | undefined;
  password: string | null | undefined;
}

interface TokenResponse {
  access_token: string | null;
  refresh_token: string | null;
  user: SessionUser;
}

interface RefreshTokenArgs {
  refresh: string;
}

interface RefreshTokenResponse {
  access: string | null;
  access_token_expiry: string | null;
}

const baseUrl: string = (typeof Window === "undefined" ? process.env.NEXT_PUBLIC_ZETTLOR_API_URL : "") + "/api";
const authUrl: string = `${baseUrl}/auth`;
export default class ZettlorAuthService {
  async login(credentials: Credentials): Promise<ServiceResponse<TokenResponse>> {
    try {
      const { data } = await axios.post(`${authUrl}/login`, credentials);
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async logout(): Promise<ServiceResponse<any>> {
    try {
      const { data } = await axios.post(`${authUrl}/logout`);
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async refreshToken(args: RefreshTokenArgs): Promise<ServiceResponse<RefreshTokenResponse>> {
    try {
      const { data } = await axios.post(`${authUrl}/token/refresh`, args);
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async signup(
    user: {
      email: string;
      username: string;
      password1: string;
      password2: string;
    },
    extraData: {
      first_name: string;
      last_name: string;
      selected_role?: string;
      invitation_uuid?: string;
      job_application_uuid?: string;
      network_member_uuid?: string;
      employer_onboarding_uuid?: string;
      co_incentive_code?: string;
    },
  ): Promise<ServiceResponse<TokenResponse>> {
    try {
      const { data } = await axios.post(`${authUrl}/registration?${queryString.stringify(extraData)}`, user);
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async socialLogin(
    provider: "google" | "linkedin",
    code: string,
    state: any = {},
  ): Promise<ServiceResponse<TokenResponse>> {
    try {
      const { data } = await axios.post(`${baseUrl}/auth/${provider}?${queryString.stringify(state)}`, { code });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async resetPassword(email: string): Promise<ServiceResponse<any>> {
    try {
      const { data } = await axios.post(`${baseUrl}/reset-password/start`, {
        email,
      });
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async resetPasswordToken(body: any): Promise<ServiceResponse<any>> {
    try {
      const { data } = await axios.post(`${baseUrl}/reset-password/token`, body);
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async verifyEmail(
    userId: string,
    token: string,
  ): Promise<ServiceResponse<{ success: boolean; selected_role: UserRole }>> {
    try {
      const { data } = await axios.post(`${baseUrl}/users/${userId}/verify-email/token/${token}/`);
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }

  async getInvitation(invitationsUuid: string): Promise<ServiceResponse<BaseInvitation>> {
    try {
      const { data } = await axios.get(`${baseUrl}/invitations/${invitationsUuid}`);
      return {
        data,
        success: true,
      };
    } catch (error) {
      return parseApiError(error as AxiosError | Error);
    }
  }
}
