import { BaseJobApplication, JobApplication, JobPost, NetworkMember, User } from "types/models";
import { ConnectorOrganization } from "types/models/connectorOrganization";
import { BaseNetworkMember } from "types/models/networkMemberBase";
import { UserRole } from "types/userRole";

import { PROFILE_URL } from "./constants";

export const getUserDefaultPage = (args: { user?: User; selected_role?: UserRole }): string => {
  if (args.selected_role) {
    switch (args.selected_role) {
      case UserRole.Employer:
        return "/job-posts";
      case UserRole.Connector:
        return "/dashboard";
      case UserRole.Worker:
        return "/chat";
      default:
        return PROFILE_URL;
    }
  }

  if (args.user) {
    switch (args.user.selected_role) {
      case UserRole.Employer:
        return "/job-posts";
      case UserRole.Connector:
        // TODO - if current user is an employee to an organization, add the org to the query params of search
        return "/search";
      case UserRole.Worker:
        return "/chat";
      default:
        return PROFILE_URL;
    }
  }

  return PROFILE_URL;
};

export const isJobPostAdmin = (user: User, jobPost: JobPost) => {
  return (
    user?.selected_role === UserRole.Employer && user?.organizations.some((o) => o.uuid === jobPost?.organization.uuid)
  );
};

export const isEmployeeConnector = (connectorOrganization: ConnectorOrganization): boolean => {
  return !!connectorOrganization.organization;
};

export const getUserDetails = ({
  jobApplication,
  networkMember,
}: {
  jobApplication?: JobApplication | BaseJobApplication;
  networkMember?: NetworkMember | BaseNetworkMember;
}) => {
  if (!jobApplication && !networkMember) return {};

  const user = jobApplication?.user || networkMember?.user;

  const email = user?.email || networkMember?.email;
  const first_name = user?.first_name || networkMember?.first_name;
  const last_name = user?.last_name || networkMember?.last_name;
  const full_name = first_name ? `${first_name} ${last_name || ""}` : "";
  return {
    label: full_name || email || "Candidate",
    full_name,
    first_name,
    last_name,
    email,
    phone_number: user?.phone_number || networkMember?.phone_number,
    // TODO: add location to user model
    // @ts-ignore
    location: user?.location?.description || networkMember?.location?.description,
  };
};
