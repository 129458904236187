import Link from "next/link";

import { FC, ReactNode } from "react";

import { Text } from "components/ui";

interface AuthLayoutProps {
  header?: string | ReactNode;
  children?: React.ReactNode;
}

export const AuthLayout: FC<AuthLayoutProps> = ({ children, header }) => {
  return (
    <section className="bg-zettlor-new-off-white">
      <header className="mx-auto max-w-7xl px-4 py-1 sm:px-6 sm:py-3 lg:px-8">
        <Link href="/" title="Zettlor Home Page">
          <img src="/static/zettlor-logo-new-orange.svg" alt="Zettlor Logo" className="max-h-10 p-2 md:max-h-12"></img>
          <span className="sr-only">Zettlor Home Page</span>
        </Link>
      </header>
      <div className="mx-auto w-full max-w-xl p-6 md:w-3/5 lg:w-2/5">
        <Text variant="h1">{header}</Text>
        {children}
      </div>
    </section>
  );
};
