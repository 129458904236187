import classNames from "classnames";
import { FC } from "react";

interface AuthSeparatorProps {
  className?: string;
  secondaryClassName?: string;
}

export const AuthSeparator: FC<AuthSeparatorProps> = ({ className, secondaryClassName }) => (
  <div className={classNames("relative", className)}>
    <div className="absolute inset-0 flex items-center">
      <div className="w-full border-t border-zettlor-new-black/20" />
    </div>
    <div className="relative flex justify-center text-sm">
      <span
        className={classNames(
          "bg-zettlor-new-off-white px-2 py-0.5 font-thin text-zettlor-gray-600",
          secondaryClassName,
        )}
      >
        or
      </span>
    </div>
  </div>
);
