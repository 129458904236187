import { DefaultSeoProps } from "next-seo";

import { HeadSeoProps } from "components/layout/head-seo";

export const seoConfig: {
  headSeo: Required<Pick<HeadSeoProps, "siteName" | "description">>;
  defaultNextSeo: DefaultSeoProps;
} = {
  headSeo: {
    siteName: "Zettlor",
    description:
      "Zettlor helps you source candidates that don’t exist on LinkedIn, Indeed, or ZipRecruiter. You don’t pay a dime until your new hire meets your retention requirements.",
  },
  defaultNextSeo: {
    twitter: {
      handle: "@zettlor",
      site: "@zettlor",
      cardType: "summary_large_image",
    },
  },
} as const;
